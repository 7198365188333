<template>
  <div id="team">
    <v-row>
      <v-col
        cols="12"
      >
        <v-card>
          <div
            v-if="!team"
            class="text-center"
          >
            <v-btn
              color="primary"
              outlined
              class="mb-1 mt-4"
              @click="createTeam()"
            >
              Create Team
            </v-btn>
          </div>
          <v-card-title v-if="team">
            <span class="font-weight-semibold">My Team</span>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <router-link :to="{ path: 'edit-team' }">
                <v-icon>
                  {{ icons.mdiCogOutline }}
                </v-icon>
              </router-link>
            </v-btn>
          </v-card-title>
          <div v-if="team">
            <div>
              <v-row>
                <v-col
                  class="mt-0 d-block text-center justify-center mb-0 pb-0"
                  cols="12"
                  md="4"
                  sm="12"
                  xs="12"
                >
                  <router-link :to="{ path: 'edit-team' }">
                    <v-avatar
                      class="mt-2"
                      rounded
                      size="100"
                      height="100"
                      color="white"
                    >
                      <v-img
                        class="text-center justify-center align-center d-all"
                        height="100"
                        max-width="100"
                        lazy-src="https://www.fantasykombat.com/android-chrome-192x192.png"
                        :src="team.logo || 'https://www.fantasykombat.com/android-chrome-192x192.png'"
                      />
                    </v-avatar>
                    <br>
                    <v-chip
                      v-if="team.name"
                      dark
                      class="mt-2 mb-3"
                      outlined
                    >
                      {{ team.name }}
                    </v-chip>
                    <br>
                    <div
                      v-if="loadedLeagues"
                      class="mx-2 mb-4"
                    >
                      <h4 class="mb-1 mt-1 ml-3">
                        Achievements
                      </h4>
                      <v-btn-toggle class="ml-2 ma-1 pa-2 text-xs achievements">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-badge
                              v-if="user.profile.eventsEntered"
                              dense
                              bordered
                              outlined
                              x-small
                              offset-x="23"
                              offset-y="41"
                              :content="user.profile.eventsEntered"
                            >
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{
                                  icons.mdiCalendarMultipleCheck
                                }}
                              </v-icon>
                            </v-badge>
                          </template>
                          <span>Tournaments Entered</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-badge
                              bordered
                              x-small
                              offset-x="25"
                              offset-y="41"
                              content="OG"
                            >
                              <font-awesome-icon
                                v-bind="attrs"
                                v-on="on"
                                icon="fa-duotone fa-user-secret"
                                size="2x"
                                class="ml-6 mt-0 white--text fad"/>
                            </v-badge>
                          </template>
                          <span>Original Gangster</span>
                        </v-tooltip>
                      </v-btn-toggle>
                    </div>
                  </router-link>
                </v-col>
                <v-col
                  cols="12"
                  class="mt-0"
                  md="6"
                  sm="12"
                  xs="12"
                >
                  <div v-if="teamLoaded">
                    <div class="pb-0 mt-0 pt-0 team-league-list">
                      <div class="d-block">
                        <v-chip
                          v-if="team.owner"
                          class="mx-2"
                          text-color="white"
                          large
                          :to="{ path: '/account-settings' }"
                        >
                          <v-avatar
                            left
                            size="50"
                            class="white--text font-weight-medium me-3"
                          >
                            <v-img
                              :lazy-src="defaultAvatar"
                              :src="user.photoURL||`https://avatars.dicebear.com/api/bottts/${team.owner}.svg`"
                            ></v-img>
                          </v-avatar>
                          <div class="text--primary text-base me-1 pb-0 mb-0 text-truncate">
                            {{ user.displayName }}
                            <div class="text-xs mt-0 pt-0 text-truncate">
                              Owner
                            </div>
                          </div>
                        </v-chip>
                        <v-chip
                          v-if="team.advisor"
                          class="ma-2"
                          text-color="white"
                          large
                          :to="{ path: 'advisor' }"
                        >
                          <v-avatar
                            left
                            size="50"
                            class="white--text font-weight-medium me-3"
                          >
                            <v-img
                              :lazy-src="defaultAvatar"
                              :src="team.advisor.mainImage || require('@/assets/images/avatars/default.png')"
                            ></v-img>
                          </v-avatar>

                          <div
                            v-if="team.advisor.name && team.advisor.name !== '--'"
                            class="text--primary text-base me-1 pb-0 mb-0 text-truncate"
                          >
                            {{ team.advisor.name || '--' }}
                            <div class="text-xs mt-0 pt-0 text-truncate">
                              Team Advisor
                            </div>
                          </div>
                          <div
                            v-else
                            class="text--primary text-base me-1 pb-0 mb-0 text-truncate"
                          >
                            Hire an Advisor
                          </div>
                        </v-chip>
                        <v-chip
                          v-if="captain"
                          class="ma-2"
                          text-color="white"
                          large
                        >
                          <v-avatar
                            left
                            size="50"
                            class="white--text font-weight-medium me-3"
                          >
                            <v-img
                              v-if="captain.slug"
                              height="50"
                              width="50"
                              :lazy-src="defaultAvatar"
                              :src="fighterImagesURL+'fighters/images/head-'+captain.slug+'_160x100.png'"
                            ></v-img>
                            <v-img
                              v-else
                              :lazy-src="defaultAvatar"
                              :src="defaultAvatar"
                            ></v-img>
                          </v-avatar>
                          <div class="text--primary text-base me-1 pb-0 mb-0 text-truncate">
                            {{ captain.name }}
                            <div class="text-xs mt-0 pt-0 text-truncate">
                              Team Captain
                            </div>
                          </div>
                        </v-chip>
                      </div>
                    </div>
                    <div
                      v-if="loadedLeagues"
                      class="mx-2"
                    >
                      <h4 class="mb-4 mt-3 ml-3">
                        My Leagues
                      </h4>
                      <v-slide-group
                        ref="slideGroup"
                        show-arrows
                        class="slider ma-0 pa-0 mt-1 mb-2 league-slider"
                      >
                        <v-slide-item
                          v-for="item in loadedLeagues"
                          :key="item.id"
                          v-slot="{ active}"
                          class="ma-0 pa-0"
                        >
                          <router-link :to="`/view-league?id=${item.id}`">
                            <div class="text-truncate d-block">
                              <v-avatar
                                :input-value="active"
                                active-class="success"
                                rounded
                                size="65"
                                class="ma-1 mx-3"
                                color="white"
                              >
                                <img
                                  class=" ml-auto thumbnail"
                                  color="white"
                                  height="60"
                                  max-width="60"
                                  lazy-src="https://www.fantasykombat.com/favicon-32x32.png"
                                  :src="item.logo"
                                />
                              </v-avatar>
                              <p
                                class="team-name text-xs text-center ma-0 pa-0 mx-1 d-all text-wrap"
                              >
                                {{ item.name }}
                              </p>
                            </div>
                          </router-link>
                        </v-slide-item>
                      </v-slide-group>
                      <v-btn
                        v-if="loadedLeagues.length===0"
                        outlined
                        color="success"
                        to="/leagues"
                        small
                        class="ma-3 mt-0"
                      >
                        Join a League
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-else
                    class="mt-6 mb-2"
                  >
                    <v-skeleton-loader
                      type="list-item-avatar, divider, list-item-one-line"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      type="list-item-avatar, divider, list-item-one-line"
                    ></v-skeleton-loader>
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-divider class="my-3"></v-divider>
            <h4 class="ml-6 mb-2">
              Training PowerUps
            </h4>
            <v-sheet
              class="mx-auto team-power-ups"
              max-width="1160"
            >
              <div
                class="text-center d-all powerup"
              >
                <v-chip
                  outlined
                  close-icon="mdi-close-outline"
                  class="mt-1 lime"
                >
                  <v-icon>
                    {{ icons.mdiBatteryMedium }}
                  </v-icon>
                  <span class="advisor-attr">Stamina +{{ powerUps.stamina }}%</span>
                </v-chip>

                <v-chip
                  outlined
                  close-icon="mdi-close-outline"
                  class="mt-1 warning"
                >
                  <v-icon>
                    {{ icons.mdiBatteryHeartOutline }}
                  </v-icon>
                  <span class="advisor-attr">Recovery +{{ powerUps.recovery }}%</span>
                </v-chip>

                <v-chip
                  outlined
                  close-icon="mdi-close-outline"
                  class="mt-1 red"
                >
                  <v-icon>
                    {{ icons.mdiBottleTonicPlusOutline }}
                  </v-icon>

                  <span class="advisor-attr ">Health +{{ powerUps.health }}%</span>
                </v-chip>

                <v-chip
                  outlined
                  close-icon="mdi-close-outline"
                  class="mt-1 purple"
                >
                  <v-icon>
                    {{ icons.mdiWeightLifter }}
                  </v-icon>
                  <span class="advisor-attr">Strength +{{ powerUps.strength }}%</span>
                </v-chip>

                <v-chip
                  outlined
                  close-icon="mdi-close-outline"
                  class="mt-1 brown"
                >
                  <v-icon>
                    {{ icons.mdiKabaddi }}
                  </v-icon>
                  <span class="advisor-attr">Wrestling +{{ powerUps.wrestling }}%</span>
                </v-chip>

                <v-chip
                  outlined
                  close-icon="mdi-close-outline"
                  class="mt-1 blue-grey"
                >
                  <v-icon>
                    {{ icons.mdiBoxingGlove }}
                  </v-icon>
                  <span class="advisor-attr">Boxing +{{ powerUps.boxing }}%</span>
                </v-chip>

                <v-chip
                  outlined
                  close-icon="mdi-close-outline"
                  class="mt-1 orange"
                >
                  <v-icon>
                    {{ icons.mdiKarate }}
                  </v-icon>
                  <span class="advisor-attr">Kicks +{{ powerUps.kicks }}%</span>
                </v-chip>

                <v-chip
                  outlined
                  close-icon="mdi-close-outline"
                  class="mt-1 blue"
                >
                  <v-icon>
                    {{ icons.mdiGamepadVariantOutline }}
                  </v-icon>
                  <span class="advisor-attr">Arcade Boost +{{ powerUps.arcade }}%</span>
                </v-chip>

                <v-chip
                  outlined
                  close-icon="mdi-close-outline"
                  class="mt-1 dark-green"
                >
                  <v-icon>
                    {{ icons.mdiCashMultiple }}
                  </v-icon>
                  <span class="advisor-attr">Sponsorship +{{ powerUps.sponsorship }}%</span>
                </v-chip>
                <v-chip
                  v-if="team.advisor"
                  color="grey"
                  outlined
                >
                  <span class="advisor-attr">Country:</span>
                  <country-flag
                    v-if="team.advisor.countryBoost"
                    class="ma-0 pa-0"
                    :country="team.advisor.countryBoost"
                    size="normal"
                  />

                  <span v-if="team.advisor.countryBoost">+4%</span>
                  <span v-else>+0%</span>
                </v-chip>
              </div>
              <div
                v-if="user.profile.dapperAddress"
                class="text-center"
              >
                <v-btn
                  to="/powerups"
                  outlined
                  small
                  class="mt-2"
                >
                  View Strike PowerUps
                </v-btn>
              </div>
            </v-sheet>
            <v-divider class="my-4"></v-divider>
            <div
              v-if="isCardDetailsVisible"
              class="text-center d-all mt-0 pt-0 mb-1 pb-1 text-sm hidden-sm-and-down"
            >
              <p>
                A Team consists of you the owner, 1 advisor, 1 captain and a roster of
                fighters.
              </p>
              <p>
                Team <strong>Advisors</strong> provide powerups which enhance your fighter's
                attributes based on
                their profile.
              </p>
              <p class="text-center">
                Team <strong>Captains</strong> get their earnings doubled for both fictional and
                real events.
              </p>
            </div>
            <div class=" mx-auto text-center mb-5 pb-4 hidden-sm-and-down">
              <v-btn
                icon
                @click="isCardDetailsVisible = !isCardDetailsVisible"
              >
                <v-icon>
                  {{
                    isCardDetailsVisible ? icons.mdiChevronUp : icons.mdiInformationOutline
                  }}
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="team">
      <v-card>
        <div class="pt-4 ml-3 mb-0">
          <v-btn
            outlined
            small
            class=""
            :disabled="!toggleTraining"
            @click="toggleTraining = !toggleTraining"
          >
            <span class="hidden-sm-and-down mr-1">My </span>Fighters
          </v-btn>
          <v-btn
            outlined
            small
            class="ml-2"
            :disabled="toggleTraining"
            @click="toggleTraining = !toggleTraining"
          >
            <span class="hidden-sm-and-down mr-1">Training </span>Strategy
          </v-btn>
        </div>
        <div
          v-if="!toggleTraining"
          id="team-tab"
        >
          <div class="d-flex justify-space-between align-center">
            <span
              v-if="fighters"
              class="text--primary font-weight-medium"
            >
            </span>
          </div>
          <TeamTable
            ref="teamTableRef"
            :fighters="fighters"
            :get-captain="captain"
            :loading-table="loadingTable"
            @setCaptain="updateCaptain"
          ></TeamTable>

          <div class="mx-auto text-center">
            <router-link :to="{ path: 'recruit-fighters' }">
              <v-btn
                class="mt-4 mb-4 position-relative justify-end table-recruit"
                small
                outlined
              >
                Recruit Fighters
              </v-btn>
            </router-link>
          </div>
        </div>

        <div
          v-else
          id="training-tab"
        >
          <v-container>
            <v-row class="mt-2">
              <v-col
                cols="12"
                class="col-md-3 col-sm-3 col-xs-12"
              >
                <div class="mx-auto text-center">
                  <v-avatar
                    rounded
                    size="100"
                  >
                    <v-img src="@/assets/images/avatars/Analyst.png"></v-img>
                  </v-avatar>
                </div>
              </v-col>
              <v-col
                cols="12"
                class="col-md-8 col-sm-8 col-xs-12"
              >
                <p>
                  Setting your team training strategy is a key way to differentiate your team over
                  time, choose to
                  train different attributes, the longer you keep players for the higher the "power
                  up".
                  The more "XP" (Experience Points) you have the more "Training Credits" you have to
                  allocate.
                </p>
                <p class="hidden-sm-and-down">
                  You can also set per fighter training strategies, to help individual fighters
                  better prepare for
                  upcoming fights. <small>(Coming Soon)</small>
                </p>
              </v-col>
            </v-row>
            <v-divider class="my-5"></v-divider>
            <v-row>
              <v-col cols="12">
                <v-card-text>
                  <strong>
                    Total Training Credits: </strong>
                  <v-chip
                    outlined
                    :class="{'success':trainingPerc == 100}"
                    class="mr-2"
                  >
                    {{ trainingPerc|oneDecimal }}%
                  </v-chip>
                  <p class="hidden-md-and-up"></p>
                  <strong>Credits
                    Remaining: </strong>
                  <v-chip
                    outlined
                    :class="{'primary': trainingCreditsRemaining > 1}"
                  >
                    {{
                      trainingCreditsRemaining|twoDecimal
                    }}
                  </v-chip>
                  <br>
                  <br>
                  <p>Your fighters have a limited amount of time to train each day, choose
                    your training
                    strategy
                    wisely.</p>
                  <br>
                  <div id="trainingSliders" class="mt-2">
                    <v-slider
                      v-for="(item, index) in slider"
                      :key="index"
                      v-model="item.val"
                      :label="item.label"
                      :track-fill-color="item.color"
                      :thumb-color="item.color"
                      thumb-label="always"
                      :hint="item.hint"
                      :max="maxSlider"
                      persistent-hint
                      class="mb-10"
                      @change="trainingData(index)"
                    >
                      <template v-slot:thumb-label="{value}">
                        <v-icon
                          class="pa-4"
                          dense
                        >
                          {{ slider[index].icon }}
                        </v-icon>
                        <v-badge
                          class="slider-value"
                          bordered
                          overlap
                          dense
                          :content="`${value}%`"
                        ></v-badge>
                      </template>

                      <template v-slot:append>
                        <p
                          v-if="slider[index].saving"
                          class="text-success tsaving"
                        >
                          Saving...
                        </p>
                      </template>
                    </v-slider>
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </div>
    <Dialogue
      :dialogue=" dialogue
      "
    ></Dialogue>
    <Modal
      :modal="modal"
      class="mt-10"
    ></Modal>
  </div>
</template>

<script>
import {
  mdiCogOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiTableOfContents,
  mdiViewGridOutline,
  mdiBatteryMedium,
  mdiBatteryHeartOutline,
  mdiHeartOutline,
  mdiBottleTonicPlusOutline,
  mdiWeightLifter,
  mdiRunFast,
  mdiKabaddi,
  mdiKarate,
  mdiBoxingGlove,
  mdiGamepadVariantOutline,
  mdiCashMultiple,
  mdiFoodAppleOutline,
  mdiInformationOutline,
  mdiCalendarMultipleCheck,
} from '@mdi/js'
import dbFunctions from '@/db'
import Dialogue from '@/views/components/Dialogue.vue'
import TeamTable from '@/views/components/TeamTable.vue'
import Modal from '@/views/components/Modal.vue'

export default {
  components: {
    Modal,
    Dialogue,
    TeamTable,
  },
  data() {
    return {
      defaultAvatar: 'https://static.fantasykombat.com/images/default-profile-head.png',
      isCardDetailsVisible: false,
      icons: {
        mdiCogOutline,
        mdiChevronDown,
        mdiChevronUp,
        mdiTableOfContents,
        mdiViewGridOutline,
        mdiBatteryMedium,
        mdiBatteryHeartOutline,
        mdiHeartOutline,
        mdiBottleTonicPlusOutline,
        mdiWeightLifter,
        mdiRunFast,
        mdiKabaddi,
        mdiKarate,
        mdiBoxingGlove,
        mdiGamepadVariantOutline,
        mdiCashMultiple,
        mdiFoodAppleOutline,
        mdiInformationOutline,
        mdiCalendarMultipleCheck,
      },
      toggleTraining: false,
      loadingTable: '',
      teamLoaded: false,
      leaguesLoaded: false,
      dialogue: {
        show: false,
        from: '',
        image: '',
        phrase: '',
      },
      modal: {
        title: '',
        content: '',
        show: false,
      },
      trainingPerc: 0,
      trainingCreditsAllowed: 0,
      trainingCreditsUsed: 0,
      trainingCreditsRemaining: 0,
      joinedLeague: false,
      joinCode: null,
      justJoined: {},
      maxSlider: 100,
      powerUps: {
        stamina: 0,
        recovery: 0,
        arcade: 0,
        sponsorship: 0,
        health: 0,
        strength: 0,
        speed: 0,
        wrestling: 0,
        boxing: 0,
        kicks: 0,
      },
      user: this.$store.state.user,
      team: [],
      captain: {},
      loadedLeagues: [],
      teamValue: 0,
      fighters: [],
      slider: [
        {
          id: 'cardio',
          label: 'Cardio',
          val: 10,
          icon: mdiHeartOutline,
          color: '#D4E157',
          hint: 'Improves Stamina & Health',
          saving: false,
        },
        {
          id: 'nutrition',
          label: 'Nutrition',
          val: 10,
          icon: mdiFoodAppleOutline,
          color: '#D32F2F',
          hint: 'Improves Recovery & Health ',
          saving: false,
        },
        {
          id: 'weightTraining',
          label: 'Weight Training',
          val: 10,
          icon: mdiWeightLifter,
          color: '#8E24AA',
          hint: 'Improves Strength',
          saving: false,
        },
        {
          id: 'wrestling',
          label: 'Wrestling',
          val: 10,
          icon: mdiKabaddi,
          color: '#A1887F',
          hint: 'Improves Wrestling',
          saving: false,
        },
        {
          id: 'boxing',
          label: 'Boxing',
          val: 10,
          icon: mdiBoxingGlove,
          color: '#84FFFF',
          hint: 'Improves Boxing',
          saving: false,
        },
        {
          id: 'kicks',
          label: 'Kicks',
          val: 10,
          icon: mdiKarate,
          color: '#E64A19',
          hint: 'Improves Kicks',
          saving: false,
        },
      ],
    }
  },
  mounted() {
    this.getTeam()
    this.getFighters()
  },
  methods: {
    createTeam() {
      dbFunctions.createTeam(this.user.uid)
        .then(teamData => {
          this.$router.push('/edit-team')

          return teamData
        })
        .catch(error => {
          console.log(error)
        })
    },
    getSavedTrainingData() {
      if (this.team?.training) {
        for (let i = 0; i < this.team.training.length; i += 1) {
          this.slider[i].val = this.team.training[i].val
        }
      }
      this.trainingData(1)
    },
    trainingData(index) {
      this.slider[index].saving = true
      this.trainingCreditsAllowed = 110

      // apply XP multiplier to increase training allowance
      if (this.user.profile?.totalPoints > 1) {
        const multiplier = this.team.totalPoints / 100
        this.trainingCreditsAllowed = 110 + multiplier
      }
      let tCreditsUsed = 0
      const training = []
      for (let i = 0; i < this.slider.length; i += 1) {
        tCreditsUsed += this.slider[i].val
        training[i] = {id: this.slider[i].id, val: this.slider[i].val}
      }
      this.trainingCreditsUsed = tCreditsUsed
      this.trainingCreditsRemaining = this.trainingCreditsAllowed - this.trainingCreditsUsed
      if (this.trainingCreditsUsed > this.trainingCreditsAllowed) {
        this.slider[index].val += this.trainingCreditsRemaining
        this.trainingCreditsRemaining = 0
        this.trainingPerc = 100
      } else {
        this.trainingPerc = (this.trainingCreditsUsed / this.trainingCreditsAllowed) * 100
      }

      const fields = {training}

      dbFunctions.updateTeam(this.user.uid, fields)
        .then(() => {
          this.slider[index].saving = false
        })

      window.$crisp?.push(['set', 'session:data', [
        [
          ['trainingCreditsAllowed', this.trainingCreditsAllowed],
          ['trainingCreditsUsedPercentage', this.trainingPerc],
        ],
      ]])
    },
    checkDialogue() {
      if (this.$route.params.dialogue) {
        this.$store.state.user.profile.dialogue = this.$route.params.dialogue
      }
      if (this.$store.state.user.profile?.dialogue) {
        if (this.$store.state.user.profile.dialogue.show) {
          this.dialogue = this.$store.state.user.profile.dialogue
        }
      }
    },
    getPowerUps(attributes) {
      if (attributes) {
        if (attributes.all) {
          Object.keys(this.powerUps).forEach(key => {
            this.powerUps[key] = attributes.all
          })
        }
        Object.keys(attributes).forEach(key => {
          this.powerUps[key] = attributes[key]
        })
      }
    },
    async getTeam() {
      await dbFunctions.readUsersProfile(this.user.uid)
        .then(profileData => {
          this.$store.state.user.profile = profileData
          this.user = this.$store.state.user
          localStorage.setItem('user', JSON.stringify(this.user))
          dbFunctions.getTeam(this.user.uid)
            .then(teamData => {
              this.team = teamData
              window.$crisp?.push(['set', 'session:data', ['teamName', this.team?.name]])
              if (!this.team.advisor) {
                this.isCardDetailsVisible = true
                this.team.advisor = {}
                this.team.advisor.image = require('@/assets/images/avatars/default.png')
                this.team.advisor.mainImage = require('@/assets/images/avatars/default.png')
                this.team.advisor.toonimage = require('@/assets/images/avatars/default.png')
                this.team.advisor.name = '--'
              }
              if (!this.team.captain) {
                this.isCardDetailsVisible = true
                this.captain = {}
                this.captain.image = require('@/assets/images/avatars/default.png')
                this.captain.name = '--'
              } else {
                this.captain = this.team.captain
              }
              if (this.team.leagues) {
                if (this.team.leagues.length > 0) {
                  this.joinedLeague = true
                  this.getLeagueData()
                }
              }
              this.teamLoaded = true
              this.leaguesLoaded = true
              this.getPowerUps(this.team.advisor.attributes)
              this.getSavedTrainingData()

              return teamData
            })
            .catch(error => {
              console.log(error)
            })
        })
        .catch(error => {
          console.log(error)
        })
    },
    updateCaptain(captain) {
      this.captain = captain
      let cimage = this.defaultAvatar
      if (this.captain.slug) {
        cimage = `${this.fighterImagesURL}fighters/images/head-${this.captain.slug}_160x100.png`
      }
      const dialogue = {
        show: true,
        from: this.captain.name,
        image: cimage,
        phrase: this.$options.filters.hiredPhraseGen(this.captain.hiredPhrase, 'hired'),
        dialogueType: 'captained',
        data: this.captain,
      }
      this.$store.state.user.profile.dialogue = dialogue
      this.checkDialogue()
    },
    async getLeagueData() {
      if (this.team.leagues) {
        for (let i = 0; i < this.team.leagues.length; i += 1) {
          dbFunctions.getMyLeagues(this.team.leagues[i])
            .then(data => {
              const ldata = data.data()
              if (ldata) {
                window.$crisp?.push(['set', 'session:segments', [[ldata.name]]])
                ldata.id = data.id
                if (ldata.status === 'active') {
                  this.loadedLeagues.push(ldata)
                }
              }
            })
        }
      }
    },
    async getFighters() {
      this.loadingTable = true
      await dbFunctions.getAllTeamFighters(this.user.uid)
        .then(querySnapshot => {
          this.teamValue = 0
          querySnapshot.forEach(doc => {
            const fighter = doc.data()
            fighter.uid = doc.id
            this.fighters.push(fighter)
            this.teamValue += doc.data().cost
            this.loadingTable = false
          })
          this.checkDialogue()
          this.updateData()
        })
        .catch(error => {
          console.log(error)
          this.loadingTable = false
        })
    },
    async updateData() {
      if (this.user.profile) {
        const crispData = [
          ['rank', this.user.profile.rank],
          ['silverKombatCoins', this.user.profile.silverKombatCoins],
          ['goldKombatCoins', this.user.profile.goldKombatCoins],
          ['lastPoints', this.user.profile.lastPoints],
          ['totalPoints', this.user.profile.totalPoints],
          ['eventsEntered', this.user.profile.eventsEntered],
          ['arcadePlayed', this.user.profile.arcadePlayed],
          ['silverKombatCoins', this.user.profile.silverKombatCoins],
          ['age', this.user.profile.age],
          ['onBoarded', this.user.profile.onBoardered],
          ['solanaAddress', this.user.profile.solanaAddress],
          ['fighters', this.fighters?.length],
          ['teamValue', this.teamValue],
        ]
        window.$crisp?.push(['set', 'session:data', [crispData]])
      }
    },
  },
}
</script>

<style lang="scss">

.mobile .league-slider .v-slide-group__next, .mobile .league-slider .v-slide-group__prev {
  top: 38px;
  transform: scale(1);
  position: relative
}

.achievements.v-btn-toggle {
  opacity: 0.9;
}

.achievements.v-btn-toggle .v-badge__badge.primary {
  background: #38364e !important;
  border: 1px dotted #fff;
}

.mobile .team-league-list {
  text-align: center;
}

.v-list.team-list {
  background: none;
}

.slider .team-name {
  width: 82px;
}

.mobile .slider .team-name {
  width: 80px;
}

span.team-name {
  background: #000 !important;
  color: #ffff;
  padding: 2px;
  position: absolute;
  z-index: 2;
  bottom: 5px;
  width: 120px;
  height: 40px;
  font-family: 'Black Ops One', 'Helvetica Neue', Helvetica, Arial, sans-serf, serif;
  border-radius: 0 0 4px 4px;
  display: inline-block;
  text-align: center;
  margin: auto;

}

span.team-text-wrapper {
  width: 110px;
  height: 30px;
  display: inline-block;
  margin-top: 6px;
  font-size: 12px;
}

span.team-text {
  text-align: center;
  font-size: 14px;
}

.team-avatar-img {
  margin-top: -35px;
  display: inline-block;
}

.flag.normal-flag {
  transform: scale(0.4) !important;
}

span.v-badge.slider-value.v-badge--bordered.v-badge--overlap.theme--dark {
  position: absolute;
  top: 59px;
  left: 27px;
}

#trainingSliders label.v-label.theme--dark {
  min-width: 134px;
}

#trainingSliders .v-icon svg.v-icon__svg {
  height: 20px;
}

.tsaving {
  min-width: 60px;
  position: absolute;
  right: 20px;
  padding-top: 20px;
}

.team-power-ups.v-sheet.theme--dark, .theme--dark.v-tabs > .v-tabs-bar {
  background: transparent;
}

.table-recruit.v-btn {
  z-index: 2;
}

img.rounded.league-added-logo {
  max-width: 100px;
}

.mobile .team-list .text-xs.text-truncate {
  max-width: 73px;
}

#team .v-list-item__subtitle.text-xs.text-truncate {
  max-width: 120px;
  display: block;
}

#team hr.mb-3.v-divider.theme--dark {
  margin-top: -32px;
}
</style>
