<template>
  <div class="text-center cdialogue">
    <v-dialog
      v-model="dialogue.show"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          {{ dialogue.from }} sent you a message!
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              cols="3"
              md="2"
              sm="3"
              xs="3"
            >
              <v-avatar
                size="60"
                class="white--text font-weight-medium me-3"
              >
                <v-img :src="dialogue.image"></v-img>
              </v-avatar>
            </v-col>

            <v-col
              cols="9"
              md="10"
              sm="9"
              xs="9"
              class="mt-1"
            >
              {{ dialogue.phrase }}
            </v-col>
          </v-row>
        </v-card-text>
        <FighterCard
          v-if="dialogue.dialogueType === 'postBuy'"
          :fighter="dialogue.data"
          :show="dialogue.show"
          class="pt-6"
        ></FighterCard>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            text
            @click="dialogue.show = false"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FighterCard from '@/views/components/FighterCard.vue'

export default {
  components: {
    FighterCard,
  },
  props: {
    dialogue: {
      show: Boolean,
      from: String,
      image: String,
      phrase: String,
      dialogueType: String,
      data: Array,
    },
  },
}
</script>
<style>
.v-dialog .fighterCardWrapper .infoCard, .v-dialog .fighterCardWrapper .extraInfo, .v-dialog .infoCard.slideAnimate {
  visibility: hidden;
  display: none;
}

.v-dialog .fighterCardWrapper {
  transform: scale(0.7);
  margin-top: -76px !important;
  margin-bottom: -30px;
}
</style>
