<template>
  <div class="fighterCardWrapper">
    <div
      class="infoCard"
      :class="{'slideAnimate': infoShow}"
    >
      <ul>
        <li><strong>SLpM</strong> - Significant Strikes Landed per Minute</li>

        <li><strong>Str. Acc.</strong> - Significant Striking Accuracy</li>

        <li><strong>SApM</strong> - Significant Strikes Absorbed per Minute</li>

        <li>
          <strong>Str. Def.</strong> - Significant Strike Defense (the % of opponents strikes that
          did not land)
        </li>

        <li><strong>TD Avg.</strong> - Average Takedowns Landed per 15 minutes</li>

        <li><strong>TD Acc.</strong> - Takedown Accuracy</li>

        <li><strong>TD Def.</strong> - Takedown Defense (the % of opponents TD attempts that did not
          land)
        </li>

        <li><strong>Sub. Avg.</strong> - Average Submissions Attempted per 15 minutes</li>
      </ul>
    </div>
    <div
      class="fighterCard active"
      :class="cardType"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="fighterSvg"
      >
        <clipPath
          id="svgPath"
          stroke="black"
          stroke-width="10"
        >
          <path
            id="svg_1"
            stroke="black"
            stroke-width="10"
            d="m266.38596,41.03618a33.54933,25.35292 0 0 1 -17.93327,-4.18742a32.23959,24.36316 0 0 1 -13.80258,-17.43489c-0.2015,-0.83748 -0.40299,-1.7511 -0.40299,-2.58859c0,-0.98975 -1.00749,-1.14202 -1.81348,-1.44656a164.22043,124.09987 0 0 0 -31.23211,-8.83165a259.22649,195.89507 0 0 0 -67.4009,-6.5476a256.80852,194.06784 0 0 0 -67.6024,6.62374a171.27284,129.42931 0 0 0 -31.23211,8.83165c-0.80599,0.30454 -1.81348,0.45681 -1.81348,1.44656c0,0.83748 -0.2015,1.7511 -0.40299,2.58859a32.64259,24.6677 0 0 1 -13.80258,17.43489a34.05307,25.73359 0 0 1 -17.83253,4.11128c-1.51123,0.07613 -2.11572,0.30454 -2.01497,1.5227l0,223.76044c0,2.51245 0,5.0249 0.40299,7.53735a22.16472,16.74968 0 0 0 7.95915,10.96342c3.82845,2.43632 8.36214,4.03515 13.09734,5.17717c12.49284,2.96926 24.98569,5.71012 37.47853,8.75551a391.61032,295.93632 0 0 1 50.37437,14.77017a89.36412,67.53165 0 0 1 25.18718,11.80091l0,0.07613l0,-0.07613c7.25391,-5.32944 16.22055,-8.60324 25.18718,-11.80091a430.19708,325.09598 0 0 1 50.37437,-14.77017l37.47853,-8.75551c4.73519,-1.14202 9.16813,-2.66472 13.09734,-5.17717c4.53369,-2.89313 7.25391,-6.47147 7.95915,-10.96342c0.40299,-2.51245 0.40299,-5.0249 0.40299,-7.53735l0,-89.99144l0,-130.2668l0,-3.5022c0.40299,-1.21816 -0.30225,-1.44656 -1.71273,-1.5227z"
            fill="black"
          ></path>
          <path
            id="svg_2"
            transform="rotate(90 133.65 334.752)"
            stroke="black"
            stroke-width="10"
            d="m42.64999,200.75177l91.00001,0l0,0c50.25792,0 91.00001,59.99384 91.00001,133.99999c0,74.00616 -40.74208,133.99999 -91.00001,133.99999l-91.00001,0l0,-267.99999z"
            fill="black"
          ></path>
        </clipPath>
      </svg>
      <div id="card-inner">
        <div id="card-top">
          <div class="finfo">
            <div class="ranked">
              <div v-if="fighter.rank">
                <div class="rank">
                  Rank
                </div>
                <div
                  v-if="fighter.rank==1"
                  class="value"
                >
                  <v-icon
                    small
                    class="card-icon"
                  >
                    {{ icons.mdiCrownOutline }}
                  </v-icon>
                  c
                </div>
                <div
                  v-else
                  class="value"
                >
                  {{ fighter.rank - 1 }}
                </div>
              </div>
            </div>
            <div
              v-if="fighter.country"
              class="country"
            >
              <country-flag
                :country="fighter.country"
                rounded
                size="normal"
              />
            </div>
            <div class="pstats">
              <span class="text-xs">Height:</span><span class="text-sm"> {{ fighter.height }}</span><br>
              <span class="text-xs">Reach:</span><span class="text-sm"> {{
                fighter.reach
              }}</span><br>
              <span class="text-xs">Weight:</span><span class="text-sm"> {{ fighter.weight }}</span><br>
              <span class="text-xs">Stance:</span><span class="text-sm"> {{ fighter.stance }}</span><br>
            </div>
          </div>
          <div
            class="image"
            :style="{ background: 'url(' + image + ')' }"
          ></div>
          <div class="backfont">
            {{ cardType }} Card
          </div>
        </div>
        <div
          id="card-bottom"
          @click="infoToggle"
        >
          <v-icon class="extraInfo hidden-sm-and-down">
            {{ icons.mdiInformationOutline }}
          </v-icon>
          <div class="name">
            {{ fighter.name }}
          </div>
          <div
            v-if="fighter.nickname"
            class="card-nickname"
          >
            "{{ fighter.nickname }}"
          </div>
          <div class="frecord text-center">
            Wins: <strong>{{ fighter.win || 0 }}</strong>
            Losses: <strong>{{ fighter.loss || 0 }}</strong>
            Draws: <strong>{{ fighter.draw || 0 }}</strong>
          </div>
          <div class="stats">
            <div>
              <ul>
                <li>
                  <span>
                    {{ fighter.sig_str_land_pM }}
                  </span><span>SLpM</span>
                </li>
                <li>
                  <span>
                    <v-progress-circular
                      :size="25"
                      :width="2"
                      :value="fighter.sig_str_land_pct|decimalToPercent"
                      color="#5ea769"
                    >
                      {{ fighter.sig_str_land_pct|decimalToPercent }}
                    </v-progress-circular>
                  </span>
                  <span>Str Acc</span>
                </li>
                <li><span>{{ fighter.sig_str_abs_pM }}</span><span>SApM</span></li>
                <li>
                  <span>
                    <v-progress-circular
                      :size="25"
                      :width="2"
                      :value="fighter.sig_str_def_pct|decimalToPercent"
                      color="#5ea769"
                    >
                      {{ fighter.sig_str_def_pct|decimalToPercent }}
                    </v-progress-circular>
                  </span>
                  <span>Str Def</span>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li><span>{{ fighter.td_avg }}</span><span>TD Avg</span></li>
                <li>
                  <span>
                    <v-progress-circular
                      :size="25"
                      :width="2"
                      :value="fighter.td_land_pct|decimalToPercent"
                      color="#5ea769"
                    >
                      {{ fighter.td_land_pct|decimalToPercent }}
                    </v-progress-circular>
                  </span>
                  <span>TD Acc</span>
                </li>
                <li>
                  <span>
                    <v-progress-circular
                      :size="25"
                      :width="2"
                      :value="fighter.td_def_pct|decimalToPercent"
                      color="#5ea769"
                    >
                      {{ fighter.td_def_pct|decimalToPercent }}
                    </v-progress-circular>
                  </span>
                  <span>TD Def</span>
                </li>
                <li><span>{{ fighter.sub_avg }}</span><span>Sub Avg</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mdiInformationOutline, mdiCrownOutline} from '@mdi/js'

export default {
  props: {
    fighter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      infoShow: false,
      isMobile: false,
      cardType: '',
      icons: {
        mdiInformationOutline,
        mdiCrownOutline,
      },
    }
  },
  computed: {
    image() {
      let image = require('@/assets/images/fighters/default-profile.png')
      if (this.fighter.full_body_url) {
        image = `${this.fighterImagesURL}fighters/images/body-${this.fighter.slug}_248x380.png`
      }

      return image
    },
  },
  watch: {
    fighter() {
      this.checkRanked()
    },
  },
  mounted() {
    this.isMobile = window.innerWidth < 600
    this.checkRanked()
  },
  methods: {
    infoToggle() {
      this.infoShow = !this.infoShow
    },
    checkRanked() {
      if (this.fighter.rank) {
        this.cardType = 'silver'
        if (this.fighter.rank <= 9) {
          this.cardType = 'gold'
        }
      } else {
        this.cardType = 'base'
      }
    },
  },
}
</script>

<style lang="scss">
// gold top 10
$gold1: #e1c072;
$gold2: #fdeaa7;
$gold3: #4e3d0d;

// silver ranked
$silver1: #ddd;
$silver2: #eee;
$silver3: #666;

// unranked
$color1: #333; // Main top badge
$color2: #444; // 2nd Badge Color
$color3: #ddd; // Text

// progress color
$colorP: #5ea769;

.v-overlay__content {
  position: fixed;
  top: 60px;
}

.fighterCardWrapper {
  position: relative;
  z-index: 2;
}

.mobile .fighterCardWrapper {
  transform: scale(0.75);
  margin-top: -40px !important;
  padding: 0;
}

.fighterCard {
  position: relative;
  color: #888;
  clip-path: url("#svgPath");
  -webkit-clip-path: url("#svgPath");
  width: 270px;
  height: 430px;
  margin: auto;
  display: block;
  opacity: 0;
  transition: .3s all cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: rotateY(-180deg);
  transition-delay: .1s;

  #card-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    #card-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 47%;
      background: $color1;
      overflow: hidden;
      background: linear-gradient(135deg, $color2 0%, darken($color1, 10%) 100%);

      .backfont {
        position: relative;
        top: 25px;
        line-height: 1;
        font-size: 46px;
        text-align: center;
        margin: auto;
        letter-spacing: -3px;
        opacity: 0.05 !important;
        font-weight: 700;
        font-style: italic;
        transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1) 0.4s;
        width: 100px;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      .image {
        position: absolute;
        left: 110px;
        bottom: 0;
        z-index: 0;
        height: 80%;
        width: 60%;
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: cover !important;
        opacity: 0;
        transition: .5s all ease-out 1s;
        transform: scale(1) scaleX(-1);
        transition-delay: 0.3s;
      }

      .finfo {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 3;
        height: 73%;
        width: 30%;
        box-sizing: border-box;
        padding: 0 10px;
        text-align: center;

        .ranked {
          min-height: 30px;
        }

        div {
          position: relative;
          line-height: 1;
          letter-spacing: -1px;
          font-size: 16px;
          opacity: 0;
          top: 18px;
          transition: .1s all cubic-bezier(0.075, 0.82, 0.165, 1) 0.1s;

          &.value {
            font-size: 18px;
            border-bottom: 2px solid transparentize($color2, 0.1);
          }

          &.rank {
            transition-delay: .2s;
          }

          &.country {
            transition-delay: .3s;
          }

          &.rank {
            margin-top: -14px;
            padding: 4px 0;
            border-bottom: 2px solid transparentize($color2, 0.1);
          }

          &.country {
            position: relative;
            display: block;
            width: 50px;
            height: 32px;
            margin: -4px auto -2px;

            div {
              position: relative;
              height: 100%;
              width: 100%;
            }
          }
        }

        .pstats {
          text-align: left;
          width: 100px;
        }
      }
    }

    #card-bottom {
      position: absolute;
      overflow: hidden;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 54%;
      background: $color2;
      background: linear-gradient(135deg, $color2 0%, darken($color2, 10%) 100%);

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: -1px;
        width: 100%;
        height: 3px;
        background: transparent;
      }

      .name {
        text-align: center;
        font-size: 16px;
        text-transform: uppercase;
        width: 250px;
        margin: auto;
        margin-top: 6px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
        opacity: 0;
        top: -3px;
        position: relative;
        transition: .3s all ease-out .3s;
      }

      .frecord {
        font-size: 14px;
        margin-bottom: 4px;
      }

      .stats {
        position: relative;
        margin: 0 40px;
        padding-top: 8px;
        border-top: 4px solid transparent;
        transition: .4s all cubic-bezier(0.075, 0.82, 0.165, 1) 0.4s;

        &:before {
          content: "";
          position: absolute;
          bottom: -4px;
          left: 0;
          right: 0;
          margin: 5% auto;
          height: 0%;
          width: 4px;
          transition: .4s all cubic-bezier(0.075, 0.82, 0.165, 1) 0.4s;
          background: transparent;
        }

        div {
          width: 50%;
          vertical-align: top;
          display: inline-block;
          text-transform: uppercase;
          font-size: 11px;

          &:last-child {
            ul {
              margin-left: 15px;
            }
          }
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            margin: 0;
            position: relative;
            padding: 0;
            display: block;
            margin-bottom: 12px;
            transition: .5s all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
            opacity: 0;
            left: 20px;
            font-size: 11px;

            &:nth-child(2) {
              transition-delay: 1.2s;
            }

            &:nth-child(3) {
              transition-delay: 1.4s;
            }

            &:nth-child(4) {
              transition-delay: 1.6s;
            }

            &:nth-child(5) {
              transition-delay: 1.8s;
            }

            &:nth-child(6) {
              transition-delay: 2s;
            }

            .v-progress-circular {
              margin: -5.3px -5px;
            }

            .v-progress-circular__info {
              padding: 5.5px 8px 0 0;
              color: $color3;
              text-align: center !important;
              margin: auto;
              width: 34px;
              font-size: 10px;
            }

            span {
              position: relative;
              display: inline-block;

              &:first-child {
                font-weight: 700;
                width: 29px;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }

  &.active {
    display: block;
    cursor: pointer;
    transform: rotate(0deg);
    opacity: 1;
    overflow: -webkit-paged-y;

    #card-inner {
      #card-top {
        .image {
          right: 0;
          opacity: 1;
          transition-delay: 2s;
          animation: fadeIn 2s ease-in;
          z-index: 0;
          transform: scaleX(-1);
        }

        .backfont {
          opacity: .1;
          text-align: center;
          margin: auto;
        }

        .finfo div {
          opacity: 1;
          top: 0;
        }
      }

      #card-bottom {
        .stats {
          border-top: 4px solid transparent;
          border-color: transparent;

          &:after {
            width: 25%;
          }

          &:before {
            height: 90%;
          }

          ul {
            li {
              opacity: 1;
              left: 0;
              text-align: left;
            }
          }
        }

        .name {
          opacity: 1;
          top: 0;
        }
      }
    }
  }
}

.extraInfo {
  position: absolute !important;
  right: 2px;
  margin: 5px;
}

.gold .extraInfo {
  color: #4e3d0d !important;
}

.silver .extraInfo {
  color: #666;
}

.infoCard {
  position: absolute;
  top: 54px;
  left: 0;
  font-size: 11px;
  width: 395px;
  height: 290px;
  background: #444;
  color: #fff;
  padding: 15px;
  border-radius: 0 5px 5px 0;
  line-height: 22px;
  border: 2px solid transparent;
  opacity: 0;
  transition: all .5s ease;
}

.infoCard.slideAnimate {
  opacity: 1;
  width: 342px;
  height: 279px;
  left: 246px;
  text-align: left;
}

.infoCard {
  top: 58px !important;
}

.infoCard ul {
  list-style: none;
}

.fighterCard.base, .fighterCard.base .card-icon {
  color: $color3
}

.fighterCard.gold, .fighterCard.gold .card-icon {
  color: $gold3 !important;
}

.fighterCard.silver, .fighterCard.silver .card-icon {
  color: $silver3 !important;
}

.gold #card-top {
  background: $gold1;
  background: linear-gradient(135deg, $gold2 0%, darken($gold1, 10%) 100%) !important;
}

.silver #card-top {
  background: $silver1;
  background: linear-gradient(135deg, $silver2 0%, darken($silver1, 10%) 100%) !important;
}

.gold .rank, .gold .value {
  border-bottom: 2px solid transparentize($gold2, 0.1) !important;
}

.silver .rank, .silver .value {
  border-bottom: 2px solid transparentize($silver2, 0.1);
}

.gold #card-bottom {
  background: $gold2 !important;
  background: linear-gradient(135deg, $gold2 0%, darken($gold2, 10%) 100%) !important;

  &:before {
    background: lighten($gold2, 7%) !important;
  }
}

.silver #card-bottom {
  background: $silver2 !important;
  background: linear-gradient(135deg, $silver2 0%, darken($silver2, 10%) 100%) !important;

  &:before {
    background: lighten($silver2, 7%) !important;
  }
}

.base #card-bottom {
  background: $color2 !important;
  background: linear-gradient(135deg, $color2 0%, darken($color2, 10%) 100%) !important;

  &:before {
    background: lighten($color2, 7%) !important;
  }
}

.base .stats {
  border-top: 4px solid $color2 !important;

  &:before {
    background: $color2 !important;
  }

  &:after {
    background: $color2 !important;
  }
}

.gold .stats {
  border-top: 4px solid $gold2 !important;

  &:before {
    background: $gold2 !important;
  }

  &:after {
    background: $gold2 !important;
  }
}

.silver .stats {
  border-top: 4px solid $silver2 !important;

  &:before {
    background: $silver2 !important;
  }

  &:after {
    background: $silver2 !important;
  }
}

.gold .v-progress-circular__info {
  color: $gold3 !important;
}

.silver .v-progress-circular__info {
  color: $silver3 !important;
}

.fighterCard.active:hover {
  transform: scale(1.1) !important;
}

.animate .fighterCard {
  animation: rotation 0.6s linear;
}

@keyframes rotation {
  0% {
    transform: scaleX(-1) scale(0);
    opacity: 0.1;
  }

  20% {
    transform: scaleX(1) scale(0.2);
    opacity: 0.2;
  }

  40% {
    transform: scaleX(-1) scale(0.4);
    opacity: 0.4;
  }
  60% {
    transform: scaleX(1) scale(0.6);
    opacity: 0.6;
  }

  80% {
    transform: scaleX(-1) scale(0.8);
    opacity: 0.8;
  }
  100% {
    transform: scaleX(1) scale(0.8);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

svg.fighterSvg2 {
  filter: drop-shadow(2px 4px 6px #fff)
}

div.card-nickname {
  font-size: 12px;
  font-style: italic !important;
  text-align: center;
  margin: auto;
}

</style>
