<template>
  <div>
    <v-snackbar
      v-model="snackbar.show"
      centered
      class="text-center justify-center"
    >
      <p
        :class="snackbar.color"
        class="text-center mt-4"
      >
        {{ snackbar.text }}
      </p>
      <p class="text-center mt-4">
        <v-btn
          small
          outlined
          class="text-center"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </p>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    snackbar: {
      show: false,
      text: 'string',
      color: 'string',
    },
  },
}
</script>

<style lang="scss">
p.text-warning {
  color: #f79803;
}
</style>
