<template>
  <div class="team-table">
    <v-row class="px-md-4 px-sm-2 px-2 mt-1 tteamrow">
      <v-col
        cols="3"
        class="col-3 col-xs-3 col-md-3 py-0 my-0 mx-0 pa-1 ma-1 px-md-4 px-3"
      >
        <v-text-field
          v-model="search"
          class="pl-xs-2 pl-sm-1"
          label="Search"
          @keyup="setSearchColumns"
        ></v-text-field>
      </v-col>
      <v-col
        cols="3"
        class="col-3 col-xs-3 col-md-3 py-0 my-0 mx-0 pa-1 ma-1 px-md-4"
      >
        <v-autocomplete
          v-model="selectedEvent"
          :items="ufcEvents"
          item-text="name"
          item-value="id"
          item-header="status"
          label="UFC Event"
          :loading="ufcEventsLoading"
          @change="eventSelect"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="3"
        class="col-3 col-xs-3 col-md-3 py-0 my-0 mx-0 pa-1 ma-1 px-md-4"
      >
        <v-country-select
          v-model="country"
          :country-name="false"
          class="country-select"
          placeholder="Country"
          @change="countrySelect"
        />
      </v-col>

      <v-col
        cols="3"
        class="col-3 col-xs-3 col-md-3 py-0 my-0 mx-0 pa-1 ma-1 px-md-4 px-3"
      >
        <v-select
          v-model="selectedWeight"
          :items="weightClasses"
          class="pr-xs-2 pr-sm-1"
          label="Weight Class"
          item-text="state"
          item-value="abbr"
          persistent-hint
          return-object
          single-line
          @change="searchWeightClass"
        ></v-select>
      </v-col>
    </v-row>
    <v-data-table
      :headers="computedHeaders"
      :items="computedFighters"
      :search="search"
      :custom-sort="customSort"
      :sort-by="['current_price']"
      :sort-desc="true"
      must-sort
      item-key="index"
      :expanded.sync="expanded"
      show-expand
      fixed-header
      :disable-items-per-page="true"
      :loading="loadingTable"
      loading-text="Loading..."
      no-results-text="No matching fighters founds, you can recruit & trade fighters in the marketplace"
      mobile-breakpoint="0"
      dense
    >
      <!-- name -->
      <template v-slot:item.name="{ item }">
        <v-list-item
          v-if="!isMobile"
          @click="viewCard(item)"
        >
          <v-avatar
            :size="gAvatar"
            class="white--text font-weight-medium me-2"
          >
            <v-img
              lazy-src="https://static.fantasykombat.com/images/default-profile-head.png"
              :src="fighterImagesURL+'fighters/images/head-'+item.slug+'_160x100.png'|| 'https://static.fantasykombat.com/images/default-profile-head.png'"
            >
            </v-img>
          </v-avatar>
          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="me-">
              <div class="font-weight-semibold">
                <div class="text--primary text-base me-1 text-truncate">
                  {{ item.name }}
                </div>
              </div>
              <v-list-item-subtitle
                v-if="item.nickname"
                class="text-xs"
              >
                "{{ item.nickname }}"
              </v-list-item-subtitle>
            </div>
          </div>
        </v-list-item>
        <div
          v-else
          class="mobile ffhead ml-2"
          @click="viewCard(item)"
        >
          <p class="text-center mx-auto mt-3 mb-0 pb-0">
            <v-avatar
              :size="gAvatar"
            >
              <v-img
                lazy-src="https://static.fantasykombat.com/images/default-profile-head.png"
                :src="fighterImagesURL+'fighters/images/head-'+item.slug+'_160x100.png'|| 'https://static.fantasykombat.com/images/default-profile-head.png'"
              >
              </v-img>
            </v-avatar>
          </p>
          <p class="text--primary text-truncate mt-1 mb-0 text-center font-weight-semibold">
            {{ item.name }}<br>
          </p>
          <small
            v-if="item.nickname"
            class="text-xs text-center font-italic"
          >
            "{{ item.nickname }}"
          </small>
        </div>
      </template>
      <template v-slot:item.data-table-expand="{item, expand, isExpanded }">
        <div v-if="!marktetPlaceView">
          <v-btn-toggle
            dense
            outlined
          >
            <v-btn
              v-if="item.uid === captain.uid"
              color="success"
              small
              outlined
            >
              <span><v-icon
                x-small
                color="success"
                class="mr-1"
              >{{ icons.mdiCheck }}</v-icon>Captain</span>
            </v-btn>
            <v-btn
              v-else
              small
              outlined
              :class="{success: item.uid === captain.uid}"
              @click="makeCaptain(item)"
            >
              <span>+ Captain</span>
            </v-btn>
            <v-tooltip
              v-if="!item.selling && item.status == 'pending-update'"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  Trade
                </v-btn>
              </template>
              <span>Trading window closed until UFC updates rankings on Tuesday.</span>
            </v-tooltip>

            <v-btn
              v-if="!item.selling && item.status == 'active'"
              v-model="itemKey"
              small
              outlined

              color="primary"
              @click="sell(item)"
            >
              Trade
            </v-btn>
            <v-btn
              v-if="item.selling"
              v-model="itemKey"
              small
              outlined
              color="success"
              @click="sell(item)"
            >
              Accepting offers
            </v-btn>
            <v-btn
              v-if="item.bids"
              small
              outlined
              color="success"
              @click="acceptOffer(item)"
            >
              Accept offer
            </v-btn>
          </v-btn-toggle>
        </div>
        <div v-if="marktetPlaceView">
          <v-btn-toggle
            dense
            outlined
          >
            <v-tooltip
              v-if="marktetPlaceView && item.fighter_trading_blocked"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  Recruit
                </v-btn>
              </template>
              <span>Trading window closed until UFC ranking updates on Tuesday.</span>
            </v-tooltip>
            <v-btn
              outlined
              small
              @click="expand(!isExpanded)"
            >
              Stats
            </v-btn>
            <v-btn
              v-if="!item.bids && marktetPlaceView && item.status == 'active' && !item.fighter_trading_blocked"
              v-model="itemKey"
              small
              outlined
              color="success"
              @click="makeOffer(item)"
            >
              Recruit
            </v-btn>
          </v-btn-toggle>
        </div>

        <v-btn-toggle
          v-if="admin && marktetPlaceView"
          class="admin-btns"
        >
          <v-btn
            outlined
            x-small
            :loading="autoUpdatingFighter"
            @click="updateFighterImage(item)"
          >
            Update image
          </v-btn>
          <v-btn
            x-small
            outlined
            @click="editItem(item)"
          >
            Edit
          </v-btn>
          <v-btn
            outlined
            x-small
            :loading="autoUpdatingFighter"
            @click="addUpdateFighter(item)"
          >
            Update
          </v-btn>
        </v-btn-toggle>
        <span
          v-if="item.bids"
          class="text-xs"
        >Current bids:</span>
        <span> {{ item.bids }}</span>
      </template>
      <template
        v-slot:item.last_change="{ item }"
      >
        <div
          v-if="marktetPlaceView"
          class="mchart mplacechart"
        >
          <div class="perchange-sm hidden-sm-and-up">
            {{ item|chartSeries|lastChangePer }}%
          </div>
          <VueApexCharts
            type="line"
            height="65"
            width="90"
            :options="item|chartOptions"
            :series="item|chartSeries"
          ></VueApexCharts>
          <v-tooltip
            left
            class="chart-info-mp"
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
              </span>
            </template>
            <span>The blue line shows recent movement.<br> </span>
            Last Change: <strong>{{ item|chartSeries|lastChangePer }}%</strong><br>
            Total: <strong>{{ item|chartSeries|totalChangePer }}%</strong>
          </v-tooltip>
          <div class="perchange hidden-xs-only">
            {{ item|chartSeries|lastChangePer }}%
          </div>
        </div>
        <div
          v-else
          class="mchart mainteamchart"
        >
          <p class="text-center text-xs change-icon">
            <span
              v-if="((item.current_price - item.purchase_price) / item.purchase_price) * 100 == 0 || ((item.current_price - item.purchase_price) / item.purchase_price)* 100 == -100"
            >
              0%
            </span>
            <span v-else>
              {{
                (((item.current_price - item.purchase_price) / item.purchase_price) * 100)|oneDecimal
              }}%
            </span>
            <v-icon
              v-if="((item.current_price - item.purchase_price) / item.purchase_price) * 100 == 0 || ((item.current_price - item.purchase_price) / item.purchase_price) * 100 == -100"
            >
              {{ icons.mdiApproximatelyEqual }}
            </v-icon>
            <v-icon
              v-else-if="((item.current_price - item.purchase_price) / item.purchase_price) * 100 > 0"
              color="success"
            >
              {{ icons.mdiArrowUpBold }}
            </v-icon>
            <v-icon
              v-else-if="((item.current_price - item.purchase_price) / item.purchase_price) * 100 < 0"
              color="error"
            >
              {{ icons.mdiArrowDownBold }}
            </v-icon>
          </p>

          <VueApexCharts
            v-if="!marktetPlaceView"
            class="teamchart"
            type="line"
            height="65"
            width="90"
            :options="item|chartOptions"
            :series="item|teamChartSeries"
          >
          </VueApexCharts>
          <v-tooltip
            left
            class="chart-info-mp"
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="info">{{ icons.mdiInformationOutline }}</v-icon>
              </span>
            </template>
            <span>The dotted green line indicates the amount you recruited at.<br> </span>
            <span>The blue line shows recent movement.<br> </span>
            Change since you purchased: <strong>{{
              (((item.current_price - item.purchase_price) / item.purchase_price) * 100)|oneDecimal
            }}%</strong><br>
            Last Change: <strong>{{ item|teamChartSeries|lastChangePer }}%</strong><br>
            Total Change: <strong>{{ item|teamChartSeries|totalChangePer }}%</strong>
          </v-tooltip>
        </div>
      </template>
      <template
        v-if="!marktetPlaceView"
        v-slot:item.purchase_price="{ item }"
      >
        <div class="buy-column">
          <v-img
            src="@/assets/images/buy/KombatCoinGold.png"
            max-width="18"
            class="float-left mx-1 ml-2 mt-0 d-none"
          ></v-img>
          <span class="d-none">{{ item.purchase_price|silvertoGold }}</span><br>
          <p class="my-1"></p>
          <v-img
            src="@/assets/images/buy/KombatCoinSilver.png"
            max-width="18"
            class="float-left mx-1 ml-2 mt-0"
          ></v-img>
          {{ item.purchase_price|toCurrencyNoDec }}
        </div>
      </template>
      <template v-slot:item.current_price="{ item }">
        <div
          v-if="!marktetPlaceView"
          class="tspacer"
        >
        </div>
        <div class="buy-column">
          <div class="v-curr">
            <v-img
              src="@/assets/images/buy/KombatCoinGold.png"
              max-width="18"
              class="float-left mx-1 ml-2 mt-0 d-none"
            ></v-img>
            <span class="d-none">{{ item.current_price|silvertoGold }}</span>
            <p class="my-1"></p>
            <v-img
              src="@/assets/images/buy/KombatCoinSilver.png"
              max-width="18"
              class="float-left mx-1 ml-2 mt-0"
            ></v-img>
            {{ item.current_price|toCurrencyNoDec }}
          </div>
        </div>
      </template>
      <template v-slot:item.weight="{ item }">
        {{ item.weight.slice(0, -5) }}
      </template>
      <template v-slot:item.country="{ item }">
        <div v-if="item.country">
          <country-flag
            :country="item.country"
            rounded
            size="normal"
            class="mt-2"
          />
        </div>
      </template>
      <template v-slot:item.dob="{ item }">
        <span>
          {{ getAge(item.dob) }}
        </span>
      </template>
      <template v-slot:item.rank="{ item }">
        <v-chip
          v-if="item.rank"
          class="rank-chip"
          color="grey"
          :class="{ 'silver': item.rank-1 <= 15, 'gold': item.rank-1 <= 10 }"
          small
        >
          <div v-if="item.rank==1">
            <v-icon small>
              {{ icons.mdiCrownOutline }}
            </v-icon>
            C
          </div>
          <div v-else>
            {{ item.rank - 1 }}
          </div>
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table
            dense
            dark
            class="stat-table mb-3"
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="py-2">
                  Significant strikes landed <small>(Per Minute)</small>
                </th>
                <th class="py-2">
                  Significant strikes absorbed <small>(Per Minute)</small>
                </th>
                <th class="py-2">
                  Strike accuracy
                </th>
                <th class="py-2">
                  Strike defense
                </th>
                <th class="py-2">
                  Take down accuracy
                </th>
                <th class="py-2">
                  Take down defense
                </th>
                <th class="py-2">
                  Take down average <small>(Per 15 Minutes)</small>
                </th>
                <th class="py-2">
                  Submission average <small>(Per 15 Minutes)</small>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ item.sig_str_land_pM }}</td>
                <td>{{ item.sig_str_abs_pM }}</td>
                <td>{{ item.sig_str_land_pct|decimalToPercent }}%</td>
                <td>{{ item.sig_str_def_pct|decimalToPercent }}%</td>
                <td>{{ item.td_land_pct|decimalToPercent }}%</td>
                <td>{{ item.td_def_pct|decimalToPercent }}%</td>
                <td>{{ item.td_avg }}</td>
                <td>{{ item.sub_avg }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:no-data>
        <p class="mt-4">
          You have not recruited any fighters.
        </p>
        <router-link :to="{ path: 'recruit-fighters' }">
          <v-btn
            outlined
            small
            class="mb-6"
          >
            Recruit Fighters
          </v-btn>
        </router-link>
      </template>
    </v-data-table>

    <div class="text-center">
      <v-overlay
        absolute="absolute"
        :value="showCard"
      >
        <v-card-text class="mt-3 pt-0 card-holder animate">
          <v-btn
            outlined
            color="primary"
            class="text-center d-all filled-btn"
            @click="showCard = false"
          >
            Close
          </v-btn>
          <FighterCard
            ref="fighterCardRef"
            v-click-outside="clickOffCard"
            :fighter="selectedFighter"
            :show-card="showCard"
            class="mt-6"
          ></FighterCard>
        </v-card-text>
      </v-overlay>
    </div>

    <v-dialog
      v-if="admin"
      v-model="edit"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit {{ selectedFighter.name }}</span>
        </v-card-title>
        <v-form>
          <v-card-text>
            <v-list-item
              v-for="(input, index) in selectedFighter"
              :key="index"
            >
              <v-text-field
                v-model="selectedFighter[index]"
                :label="index"
                :name="index"
                :value="input"
              ></v-text-field>
            </v-list-item>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="edit = false"
            >
              Close
            </v-btn>
            <v-btn
              outlined
              color="success"
              @click="inlineSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="trade"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ tradeAction.title }} {{ selectedFighter.name }}</span>
        </v-card-title>
        <v-form>
          <v-card-text>
            <div
              v-if="tradeAction.type=='makeoffer'"
              id="makeoffer"
              class="mt-2"
            >
              <v-row class="hidden-lg-and-up d-none">
                <v-col cols="12">
                  <p class="text-lg mb-0 pb-0">
                    Current Balances:
                  </p>
                </v-col>
                <v-col
                  cols="6"
                  class="col-sm-4 col-xs-6 mt-0 pt-0 d-none"
                  @click="selectedCurrency='Gold Kombat Coins'"
                >
                  <v-img
                    src="@/assets/images/buy/KombatCoinGold.png"
                    max-width="25"
                    class="float-left mx-1 mr-1 mt-0 d-none"
                  ></v-img>
                  <p>GKC {{ user.profile.goldKombatCoins|toCurrency }}</p>
                </v-col>

                <v-col
                  cols="6"
                  class="col-sm-4 col-xs-6 mt-0 pt-0"
                  @click="selectedCurrency='Silver Kombat Coins'"
                >
                  <v-img
                    src="@/assets/images/buy/KombatCoinSilver.png"
                    max-width="25"
                    class="float-left mx-1 mr-1 mt-0"
                  ></v-img>
                  <p>SKC {{ user.profile.silverKombatCoins|toCurrencyNoDec }}</p>
                </v-col>
                <v-divider class="col-sm-12 col-xs-12 col-12"></v-divider>
              </v-row>

              <v-select
                v-model="selectedCurrency"
                color="success"
                :items="currencies"
                item-text="description"
                item-value="currencies"
                label="Currency"
                class="hidden-md-and-down d-none"
              ></v-select>
              <p class="text-lg">
                Agree to asking amount:
              </p>
              <v-row>
                <v-col
                  cols="6"
                  class="col-sm-3 col-xs-6 col-md-3 d-none"
                  @click="selectedCurrency='Gold Kombat Coins'"
                >
                  <v-img
                    src="@/assets/images/buy/KombatCoinGold.png"
                    max-width="25"
                    class="float-left mx-1 mr-1 mt-0 d-none"
                  ></v-img>
                  <p class="d-none">
                    GKC {{ selectedFighter.cost|silvertoGold|oneDecimal }}
                  </p>
                </v-col>
                <v-col
                  cols="6"
                  class="col-sm-6 col-xs-6 col-md-6"
                  @click="selectedCurrency='Silver Kombat Coins'"
                >
                  <v-img
                    src="@/assets/images/buy/KombatCoinSilver.png"
                    max-width="25"
                    class="float-left mx-1 mr-1 mt-0"
                  ></v-img>
                  <p>SKC {{ selectedFighter.cost|toCurrencyNoDec }}</p>
                </v-col>
                <v-col
                  cols="6"
                  class="col-sm-3 col-xs-6 col-md-3 d-none"
                  @click="selectedCurrency='SOL (Solana Tokens)'"
                >
                  <v-avatar
                    size="23"
                    class="d-none"
                  >
                    <v-img
                      src="@/assets/images/buy/solana.png"
                    ></v-img>
                  </v-avatar>
                  <span class="ml-1">SOL {{ toSol|fourDecimal }}</span>
                </v-col>
                <v-col
                  cols="6"
                  class="col-sm-2 col-xs-6 col-md-2 px-0 mx-0 d-none"
                  @click="selectedCurrency='$ USD (Coming soon)'"
                >
                  <p class="ml-4 d-none">
                    USD ${{ toUSD|twoDecimal }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="text-lg">
                <v-col
                  cols="12"
                  class="text-center d-all justify-center"
                >
                  <v-btn
                    outlined
                    small
                    :loading="buyLoading"
                    color="success"
                    class="text-center d-all justify-center"
                    @click="buy(false)"
                  >
                    Recruit using {{ selectedCurrency }}
                  </v-btn>
                  <p
                    v-if="NFTstatus"
                    class="mt-6 text-sm"
                  >
                    Status: {{ NFTstatus }}
                  </p>
                </v-col>
              </v-row>
              <v-divider class="my-5 col-sm-12 col-xs-12 col-12"></v-divider>
              <p class="text-lg">
                Make an offer:
              </p>
              <v-currency-field
                v-model="offer"
                class="coin-amount"
                type="number"
                color="success"
                :label="`Make an offer in ${selectedCurrency}`"
                @change="setCoins"
              ></v-currency-field>

              <v-row class="text-lg">
                <v-col
                  cols="12"
                  class="text-center d-all justify-center"
                >
                  <v-btn
                    outlined
                    small
                    color="success"
                    :disabled="offer <= 0.1"
                    class="text-center d-all justify-center"
                    @click="sendOffer"
                  >
                    Send offer <span
                    v-if="offer >= 0.1"
                    class="ml-1"
                  >  of {{ offer|toCurrency }} {{ selectedCurrency }}</span>
                  </v-btn>
                  <p class="text-center text-xs mt-3 pt-0">
                    (Offers are valid for 7 days and are automatically cancelled if not accepted.)
                  </p>
                </v-col>
              </v-row>
            </div>
            <div
              v-if="tradeAction.type=='sell'"
              class="text-center"
            >
              <p>Other players can recruit your fighters on the marketplace.</p>
              <v-alert
                v-if="noMinSet && selectedFighter.minOffer < 1"
                color="error"
              >
                Please set a
                minimum.
              </v-alert>
              <v-currency-field
                v-model="selectedFighter.minOffer"
                class="coin-amount"
                type="number"
                color="success"
                :label="`Set a minimum in Silver KombatCoins:`"
                @change="setCoins"
              ></v-currency-field>
              <p>
                You will be notified of offers which you can decide to accept or deny.<br><small>(Offers
                expire after 7 days if no action is taken.)</small>
              </p>
              <v-divider class="ma-5"></v-divider>
              <v-currency-field
                v-model="buyNow"
                class="coin-amount"
                type="number"
                color="success"
                label="Set &quot;Instant Trade&quot; amount in Silver KombatCoins:"
                @change="setCoins"
              ></v-currency-field>
              <p>Any recruitment offers above the "Instant Trade" amount will be confirmed
                automatically.</p>
              <small>(You can continue to use your Fighter until traded)</small>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              text
              @click="closeTrade"
            >
              Close
            </v-btn>
            <v-btn
              v-if="tradeAction.type=='sell'"
              outlined
              color="primary"
              @click="confirmSell"
            >
              Trade
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <SnackBar :snackbar="snackbar"></SnackBar>
    <Wallet
      v-if="openWallet"
      :selected-currency="selectedCurrency"
      :fixed-amount="fixedAmount"
      :sol-purchase="solPurchase"
      :fighter-item="selectedFighter"
    ></Wallet>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiCalendar,
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiAccountStarOutline,
  mdiCrownOutline,
  mdiPencil,
  mdiDelete,
  mdiArrowUpBold,
  mdiArrowDownBold,
  mdiApproximatelyEqual,
  mdiInformationOutline,
  mdiCheck,
} from '@mdi/js'
import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/functions'
import VueApexCharts from 'vue-apexcharts'
import FighterCard from '@/views/components/FighterCard.vue'
import SnackBar from '@/views/components/SnackBar.vue'
import dbFunctions from '@/db'
import tokenFunctions from '@/tokens'
import Wallet from '@/views/components/Wallets.vue'

export default {
  components: {
    FighterCard,
    SnackBar,
    Wallet,
    VueApexCharts,
  },
  props: {
    fighters: Array,
    marktetPlaceView: Boolean,
    loadingTable: Boolean,
    getCaptain: Object,
  },
  setup() {
    return {
      edit: false,
      trade: false,
      payApproved: false,
      country: '',
      group: false,
      selectedCurrency: 'Silver Kombat Coins',
      NFTstatus: '',
      toSol: 0,
      toUSD: 0,
      buyLoading: false,
      tradeAction: {
        title: '',
        type: '',
      },
      expanded: [],
      search: '',
      admin: false,
      icons: {
        mdiCalendar,
        mdiMagnify,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiAccountStarOutline,
        mdiCrownOutline,
        mdiPencil,
        mdiDelete,
        mdiArrowUpBold,
        mdiArrowDownBold,
        mdiApproximatelyEqual,
        mdiInformationOutline,
        mdiCheck,
      },
    }
  },
  data() {
    return {
      defaultAvatar: 'https://static.fantasykombat.com/images/default-profile-head.png',
      itemKey: 'fighter_id',
      formData: {},
      gAvatar: 40,
      isMobile: false,
      currencies: [],
      currencyList: [],
      openWallet: false,
      buyNow: 0,
      noMinSet: false,
      solPurchase: false,
      showCard: false,
      team: {},
      autoUpdatingFighter: false,
      selectedFighter: [],
      selectedWeight: [],
      weightClasses: [
        {state: 'Strawweight: 115 lb (52.5 kg)', abbr: '115'},
        {state: 'Flyweight: 125 lb (56.7 kg)', abbr: '125'},
        {state: 'Bantamweight: 135 lb (61.2 kg)', abbr: '135'},
        {state: 'Featherweight: 145 lb (65.8 kg)', abbr: '145'},
        {state: 'Lightweight: 155 lb (70.3 kg))', abbr: '155'},
        {state: 'Welterweight: 170 lb (77.1 kg)', abbr: '170'},
        {state: 'Middleweight: 185 lb (83.9 kg)', abbr: '185'},
        {state: 'Light Heavyweight: 205 lb (102.1 kg)', abbr: '205'},
        {state: 'Heavyweight: 265 lb (120.2 kg)', abbr: '265'},

      ],
      ufcEventsLoading: true,
      selectedEvent: [],
      ufcEvents: [],
      filteredFighters: [],
      newFightersList: [],
      captain: '',
      offer: 0,
      user: this.$store.state.user,
      snackbar: {
        show: false,
        text: '',
        color: 'text-warning',
      },
      marketPlaceViewHeaders: [
        {text: 'Fighter', value: 'name', filterable: true},
        {
          text: '',
          value: 'data-table-expand',
          sortable: false,
          filterable: true,
        },
        {text: 'Market Value', value: 'current_price', filterable: false},
        {text: 'Change', value: 'last_change', filterable: false},
        {text: 'Country', value: 'country', filterable: false},
        {text: 'Rank', value: 'rank'},
        {text: 'Wins', value: 'win'},
        {text: 'Losses', value: 'loss'},
        {text: 'Draws', value: 'draw'},
        {text: 'Weight', value: 'weight'},
        {text: 'Height', value: 'height', hide: 'smAndDown'},
        {text: 'Reach', value: 'reach', hide: 'smAndDown'},
        {
          text: 'Age',
          value: 'dob',
          filterable: false,
        },
        {
          text: 'Stance',
          value: 'stance',
          sortable: false,
          filterable: false,
          hide: 'smAndDown',
        },
      ],
      teamViewHeaders: [
        {text: 'Fighter', value: 'name', filterable: true},
        {
          text: '',
          value: 'data-table-expand',
          sortable: false,
          filterable: true,
        },
        {text: 'Market Value', value: 'current_price', filterable: false},
        {text: 'Change', value: 'last_change', filterable: false},
        {text: 'Country', value: 'country', filterable: false},
        {text: 'Rank', value: 'rank'},
        {text: 'Wins', value: 'win'},
        {text: 'Losses', value: 'loss'},
        {text: 'Draws', value: 'draw'},
        {text: 'Weight', value: 'weight'},
        {text: 'Height', value: 'height', hide: 'smAndDown'},
        {text: 'Reach', value: 'reach', hide: 'smAndDown'},
        {
          text: 'Age',
          value: 'dob',
          filterable: false,
        },
        {
          text: 'Stance',
          value: 'stance',
          filterable: false,
          hide: 'smAndDown',
        },
      ],
    }
  },
  computed: {
    computedHeaders() {
      let headers = this.teamViewHeaders
      if (this.marktetPlaceView) {
        headers = this.marketPlaceViewHeaders
      }

      return headers.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])
    },
    computedFighters() {
      let {fighters} = this
      const {newFightersList} = this
      let heavyWeights = false
      if (this.selectedWeight.abbr === '265') {
        heavyWeights = true
      }
      if (heavyWeights) {
        for (let i = 0; i < fighters.length; i += 1) {
          const weight = fighters[i].weight.replace(' lbs.', '')
          if (weight > 205) {
            newFightersList.push(fighters[i])
          }
        }
        fighters = newFightersList

        return fighters
      }
      if (this.filteredFighters.length > 0) {
        for (let i = 0; i < fighters.length; i += 1) {
          if (this.filteredFighters.includes(fighters[i].fighter_id)) {
            newFightersList.push(fighters[i])
          } else if (this.filteredFighters.includes(fighters[i].ufc_slug)) {
            newFightersList.push(fighters[i])
          } else if (this.filteredFighters.includes(fighters[i].slug)) {
            newFightersList.push(fighters[i])
          }
        }
        fighters = newFightersList
      }

      return fighters
    },
  },
  mounted() {
    this.checkUser()
    this.getCurrencies()
    this.setCaptain()
    this.getEvents()
    this.isMobile = this.$parent.$parent.$parent.$parent.$parent.$parent.isMobile
    if (this.isMobile) {
      this.gAvatar = 30
    }
  },
  methods: {
    checkUser() {
      dbFunctions.readUsersProfile(this.user.uid)
        .then(profileData => {
          this.$store.state.user.profile = profileData
          this.user = this.$store.state.user
          this.admin = this.user.profile?.admin
          localStorage.setItem('user', JSON.stringify(this.user))
        })
      dbFunctions.getTeam(this.user.uid)
        .then(teamData => {
          if (teamData) {
            this.team = teamData
            this.captain = this.team.captain
          }
        })
    },
    customSort(items, index, isDesc) {
      items.sort((aa, bb) => {
        if (index[0] === 'rank') {
          let res = 0
          let a = Number(aa.rank)
          let b = Number(bb.rank)
          if (isDesc[0]) {
            if (a === null || a === 0) { // nulls sort after anything else
              a = 18
            }
            if (b === null || b === 0) { // nulls sort after anything else
              b = 18
            }
            res = a - b
          } else {
            if (a === null || a === 0) { // nulls sort after anything else
              a = -1
            }
            if (b === null || b === 0) { // nulls sort after anything else
              b = -1
            }
            res = b - a
          }

          return res
        }

        if (!isDesc[0]) {
          return aa[index[0]] < bb[index[0]] ? -1 : 1
        }

        return bb[index[0]] < aa[index[0]] ? -1 : 1
      })

      return items
    },
    clickOffCard() {
      this.showCard = false
    },
    async getCurrencies() {
      await dbFunctions.getCurrencies()
        .then(currencyList => {
          this.currencies = currencyList
        })
    },
    setCaptain() {
      this.captain = this.getCaptain
    },
    itemToGold(value) {
      this.toGold = value * this.currencies.GKC.exchange
    },
    getExchange(ticker) {
      const currencyList = this.currencies

      return currencyList.findIndex(element => {
        if (element.ticker === ticker) {
          return true
        }

        return false
      })
    },
    getRates() {
      const value = this.selectedFighter.cost
      const usd = this.currencies[this.getExchange('USD')].exchange
      const sol = this.currencies[this.getExchange('SOL')].exchange

      this.toSol = (value * usd) * sol
      this.toUSD = value * usd
    },
    viewCard(item) {
      this.selectedFighter = item
      this.showCard = true
    },
    getAge(dob) {
      if (!dob) {
        return ''
      }
      const bYear = dob.slice(-4)
      const nYear = new Date().getFullYear()

      return parseInt(nYear, 10) - parseInt(bYear, 10)
    },
    editItem(item) {
      this.selectedFighter = item
      this.edit = true
    },
    inlineSave() {
      dbFunctions.updateFighter(this.selectedFighter.slug, this.selectedFighter)
        .then(() => {
          this.edit = false
          this.addUpdateFighter(this.selectedFighter)
        })
    },
    makeCaptain(fighter) {
      const fields = {captain: fighter}
      dbFunctions.updateTeam(this.user.uid, fields)
        .then(() => {
          this.captain = fighter
          this.$emit('setCaptain', fighter)
        })
    },
    addUpdateFighter(fighterData) {
      console.log('update fighter')
      this.autoUpdatingFighter = true
      const api = axios.create({
        baseURL: this.$baseURL,
        timeout: 10 * 60 * 1000, // whatever time you want
      })
      api.get(`fighter/?id=${fighterData.fighter_id}`).then(() => {
        this.autoUpdatingFighter = false
        this.fighterId = ''
        console.log('update fighter')
        this.$forceUpdate()

        // console.log(response)
        if (fighterData.token_template_id) {
          console.log('template sync')
          console.log(fighterData)
          const startSyncTemplate = firebase.functions().httpsCallable('syncTemplates')
          startSyncTemplate(fighterData)
            .then(() => {
              dbFunctions.syncFighterBaseMintedTemplate(fighterData)
                .then(() => {
                  console.log('sync complete')
                })
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          console.log('no template id')
        }
      }).catch(err => {
        console.log(err)
        this.autoUpdatingFighter = false
      })
    },
    async updateFighterImage(fighterData) {
      console.log('get image')
      dbFunctions.updateImage(fighterData)
    },
    async setCoins(e) {
      this.offer = e
    },
    makeOffer(fighter) {
      console.log('Start make offer flow')
      this.trade = true
      this.selectedFighter = fighter
      this.tradeAction.title = 'Recruit'
      this.tradeAction.type = 'makeoffer'
      this.getCurrencies()
      this.getRates()
    },
    async buy(approved) {
      let checkBalance = false
      let currencyField = 'silverKombatCoins'
      if (this.selectedCurrency === 'Silver Kombat Coins') {
        currencyField = 'silverKombatCoins'
        checkBalance = true
      } else if (this.selectedCurrency === 'Gold Kombat Coins') {
        currencyField = 'goldKombatCoins'
        checkBalance = true
      }

      if (checkBalance) {
        let {cost} = this.selectedFighter
        if (currencyField === 'goldKombatCoins') {
          cost = this.$options.filters.silvertoGold(this.selectedFighter.cost)
        }
        await dbFunctions.checkBalance(this.user, currencyField, cost)
          .then(balanceData => {
            if (balanceData.sufficientBalance) {
              this.snackbar.text = 'You don\'t have enough coins :-('
              this.snackbar.show = true
              this.snackbar.color = 'text-warning'
            } else {
              this.NFTstatus = 'Negotiating contract'
              this.buyLoading = true
              dbFunctions.completeCoinTransaction(this.user, currencyField, balanceData.balance, cost)
                .then(() => {
                  this.getFighterTokenTemplate(this.selectedFighter)
                  dbFunctions.readUsersProfile(this.user.uid)
                    .then(profileData => {
                      this.$store.state.user.profile = profileData
                      this.user = this.$store.state.user
                      localStorage.setItem('user', JSON.stringify(this.user))
                    })
                })
                .catch(err => {
                  this.buyLoading = false
                  console.log(err)
                })
            }
          })
      } else if (approved) {
        this.getFighterTokenTemplate(this.selectedFighter)
      } else {
        this.openWallet = false
        this.solPurchase = true
        if (this.selectedCurrency === 'SOL (Solana Tokens)') {
          this.fixedAmount = this.toSol
        }
        this.openWallet = true
      }
    },
    closeTrade() {
      this.openWallet = false
      this.trade = false
    },
    closeWallet() {
      this.openWallet = false
    },
    solApproved() {
      this.getFighterTokenTemplate(this.selectedFighter)
    },
    sendOffer() {
      let {cost} = this.selectedFighter
      if (this.selectedCurrency === 'Gold Kombat Coins') {
        cost = parseInt(this.$options.filters.silvertoGold(this.selectedFighter.cost), 10)
      }
      if (this.selectedCurrency === 'SOL (Solana Tokens)') {
        this.offer = this.toSol
      }
      if (this.offer >= cost) {
        this.snackbar.text = 'Your offer is higher than the "Asking Price", agree to the "Asking Price" to recruit this fighter!'
        this.snackbar.show = true
        this.snackbar.color = ''
      } else {
        this.trade = false
        this.snackbar.text = 'Offer has been sent!'
        this.snackbar.show = true
        this.snackbar.color = ''
      }
    },
    acceptOffer() {
      this.trade = true
    },
    sell(fighter) {
      this.selectedFighter = fighter
      this.trade = true
      this.buyNow = fighter.buyNow
      this.tradeAction.title = 'Trade'
      this.tradeAction.type = 'sell'
    },
    async confirmSell() {
      if (!this.selectedFighter.minOffer) {
        this.noMinSet = true
      } else {
        let selling = false

        if (this.selectedFighter.minOffer > 0 || this.buyNow > 0) {
          selling = true
        }
        await dbFunctions.updateMintedFighter(this.selectedFighter.uid, {
          selling,
          minOffer: this.selectedFighter.minOffer,
          buyNow: this.buyNow || 0,
        }).then(() => {
          this.selectedFighter.selling = selling
          this.selectedFighter.buyNow = this.buyNow

          this.trade = false
        }).catch(err => {
          console.log(err)
        })
      }
    },
    async getFighterTokenTemplate(fighterData) {
      const fighter = fighterData
      fighter.description = `UFC Fighter ${fighter.name} base card`
      if (fighter.token_template_id) {
        fighter.purchase_price = fighter.cost
        fighter.current_price = fighter.cost
        this.mintFighter(fighter.token_template_id)
      } else {
        const templateData = {
          name: fighter.name,
          cap: fighter.cap || '10000',
          type: 'NFT',
          props: fighter,
          slug: fighter.slug,
          card_type: fighter.card_type || 'base',
        }
        await tokenFunctions.createTokenTemplate(templateData)
          .then(templateId => {
            this.selectedFighter.token_template_id = templateId
            this.mintFighter(templateId)
          }).catch(err => {
            this.buyLoading = false
            console.log(err)
          })
      }
    },
    async mintFighter(templateId) {
      this.NFTstatus = 'Contract negotiation started'
      await tokenFunctions.mintToken(this.user.profile.starDustPlayerId, templateId, 1, this.user)
        .then(mintedTokenId => {
          this.selectedFighter.mintedTokenId = mintedTokenId[0] // eslint-disable-line
          if (mintedTokenId) {
            this.NFTstatus = 'Contract Completed'
            dbFunctions.saveMintedFighter(this.selectedFighter, this.user.uid)
              .then(() => {
                const dialogue = {
                  show: true,
                  from: this.selectedFighter.name,
                  image: `${this.fighterImagesURL}fighters/images/head-${this.selectedFighter.slug}_160x100.png`,
                  phrase: this.$options.filters.hiredPhraseGen(this.selectedFighter.hiredPhrase, 'hired'),
                  dialogueType: 'postBuy',
                  data: this.selectedFighter,
                }
                this.trade = false
                this.buyLoading = false
                this.$router.push({name: 'my-team', params: {dialogue}})
              })
          }
        })
        .catch(err => {
          this.buyLoading = false
          console.log(err)
        })
    },
    async startTokenMint() {
      let playerId = ''
      this.NFTstatus = 'Checking player wallet ID'
      if (this.user.profile.starDustPlayerId) {
        playerId = this.user.profile.starDustPlayerId
        this.getFighterTokenTemplate(this.selectedFighter)
      } else {
        const getPlayerId = firebase.functions().httpsCallable('getPlayerId')
        getPlayerId({uid: this.user.uid})
          .then(data => {
            dbFunctions.updateUsersProfile(this.user, {
              starDustPlayerId: data.data.playerId,
            })
              .then(() => {
                this.NFTstatus = 'Internal wallet ID Check OK'
                playerId = data.data.playerId
                this.user.profile.starDustPlayerId = playerId
                this.getFighterTokenTemplate(this.selectedFighter)

                return playerId
              })
              .catch(err => {
                this.buyLoading = false
                console.log(err)
              })
          })
          .catch(err => {
            this.buyLoading = false
            console.log(err)
          })
      }

      return playerId
    },
    getEvents() {
      this.ufcEventsLoading = true
      dbFunctions.nextUFCeventFighters()
        .then(data => {
          data.push({
            name: 'All',
            timestamp: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getTime(),
          })
          this.ufcEvents = data.sort((a, b) => b.timestamp - a.timestamp)
          this.ufcEventsLoading = false
        })
    },
    eventSelect() {
      this.filteredFighters = []
      this.newFightersList = []
      if (this.selectedEvent !== 'All') {
        this.ufcEventsLoading = true
        dbFunctions.getUFCEvent(this.selectedEvent)
          .then(data => {
            if (data.version === 2) {
              Object.entries(data.fights).forEach(value => {
                this.filteredFighters.push(value[1].fighter_1_slug)
                this.filteredFighters.push(value[1].fighter_2_slug)
              })
            } else {
              for (let i = 0; i < data.fights.length; i += 1) {
                this.filteredFighters.push(data.fights[i].fighter_1_id)
                this.filteredFighters.push(data.fights[i].fighter_2_id)
              }
            }
            this.$forceUpdate()
            this.ufcEventsLoading = false
          })
      }
    },
    setSearchColumns() {
      if (this.search.length === 2) {
        if (this.marktetPlaceView) {
          this.marketPlaceViewHeaders[0].filterable = true
          this.marketPlaceViewHeaders[4].filterable = false
        } else {
          this.teamViewHeaders[0].filterable = true
          this.teamViewHeaders[4].filterable = false
        }
      } else {
        this.country = null
        if (this.marktetPlaceView) {
          this.marketPlaceViewHeaders[0].filterable = true
          this.marketPlaceViewHeaders[4].filterable = true
        } else {
          this.teamViewHeaders[0].filterable = true
          this.teamViewHeaders[4].filterable = true
        }
      }
    },
    countrySelect(e) {
      this.selectedWeight = []
      if (this.marktetPlaceView) {
        this.marketPlaceViewHeaders[0].filterable = false
        this.marketPlaceViewHeaders[4].filterable = true
      } else {
        this.teamViewHeaders[0].filterable = false
        this.teamViewHeaders[4].filterable = true
      }
      this.search = e
    },
    searchWeightClass(e) {
      this.search = e.abbr
      if (this.search.length === 3) {
        if (this.marktetPlaceView) {
          this.marketPlaceViewHeaders[0].filterable = true
          this.marketPlaceViewHeaders[4].filterable = false
        } else {
          this.teamViewHeaders[0].filterable = true
          this.teamViewHeaders[4].filterable = false
        }
        this.country = null
        if (this.selectedWeight.abbr === '265') {
          this.search = null
        }
      }
    },
  },
}
</script>

<style>

.mobile th.text-start {
  font-size: 11px !important;
}

.tteamrow span.v-icon.notranslate.theme--dark {
  right: -9px !important;
}

.mobile .tteamrow label.v-label.theme--dark, .mobile .tteamrow input {
  font-size: 10px !important;
  margin: 0px 0px -5px 0px;
}

.mobile .team-table th span {
  font-size: 10px;
}

.mobile .v-item-group.theme--dark.v-btn-toggle.v-btn-toggle--dense {
  transform: scale(0.8);
}

.mobile.ffhead {
  width: 102px;
}

.mobile .team-table td.text-start {
  padding: 0px !important;
}

.ffhead small.text-xs.text-center.font-italic {
  font-size: 9px !important;
  text-align: center;
  position: relative;
  display: block;
}

.stat-table {
  background: #ddd !important;
  border-radius: 0 0 5px 5px !important;
  margin-bottom: 6px;
}

.mobile .team-table {
  background: #312D4B;
  border-radius: 5px;
}

.stat-table.theme--dark.v-data-table th {
  background: #3b355a !important;
  border-radius: 0 !important;
}

.stat-table.theme--dark.v-data-table td {
  color: #3b355a !important;
}

.buy-column {
  min-width: 100px;
}

small.text-center.fighter-nick {
  width: 100px;
  margin: auto;
}

button.filled-btn {
  background: #3b355a;
  box-shadow: 0 0 10px #888 !important;
}

.table-avatar {
  cursor: pointer;
}

.country-select path {
  stroke: #ddd;
  fill: #ddd;
}

p.text-center.text-xs.change-icon {
  margin: 13px 0px -27px 15px;
}

.change-icon svg.v-icon__svg {
  height: 16px;
  margin: -2px -2px 1px -3px;
}

.perchange {
  margin: -28px 28px 0 48px;
  font-size: 12px;
  color: #fff;
}

.perchange-sm {
  margin: 15px 10px -30px 24px;
  font-size: 12px;
  color: #fff;
  text-align: center;
}

.v-data-table__mobile-row__cell .normal-flag {
  margin: 0 -14px 0 0;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row .mchart {
  margin-right: -10px;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 32px;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row .tspacer {
  margin-top: 20px;
}

.mobile .team-table .v-card__text {
  padding: 0;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row .buy-column {
  margin: 17px 0px -2px 0;
}

.teamchart .apexcharts-yaxis-annotations line {
  stroke: #56ca00 !important;
}

.mplacechart span.v-icon.notranslate.theme--dark, .mainteamchart span.info.v-icon.notranslate.theme--dark {
  float: right;
  height: 0px;
  margin-top: -26px;
}

.mplacechart span.v-icon.notranslate.theme--dark svg, .mainteamchart span.v-icon.notranslate.theme--dark.info svg {
  height: 16px;
}

.mplacechart, .mainteamchart {
  margin-left: -26px;
}

.team-table label.v-label.theme--dark {
  font-size: 12px;
  opacity: 0.8;
}

.team-table .country-select svg {
  height: 19px;
  opacity: 0.8;
}

.team-table .v-list-item.v-list-item--link.theme--dark {
  margin: 8px 0;
  padding: 4px;
}

.team-table .text--primary.text-base.me-1.text-truncate {
  max-width: 119px;
  font-size: 14px !important;
}

.mobile .team-table .v-list-item.v-list-item--link {
  font-size: 11px !important;
  line-height: 6px !important;
  max-width: 50px !important;
  argin: auto;
  text-align: center;
}

.mobile .team-table .text--primary.text-base.me-1 {
  max-width: 64px;
  font-size: 11px !important;
}

.team-table .v-list-item__subtitle.text-xs {
  font-size: 10px !important;
}

.team-table .v-list-item.v-list-item--link.theme--dark {
  max-width: 172px;
}

.team-table td.text-start {
  padding: 0 13px !important;
}

.team-table th span {
  margin: -7px -9px -9px -4px !important;
  height: 20px !important;
  padding: 0;
  float: left;
  max-width: 92px;
}

.team-table td.text-start {
  font-size: 12px !important;
}

.team-table span.v-icon.notranslate.v-data-table-header__icon.theme--dark {
  float: right;
  margin: -7px -17px -16px 0 !important;
}

.v-data-footer__select {
  display: none;
}

.team-table .v-list-item--link:before, .team-table .v-list-item--link:active {
  background-color: transparent;
}

.admin-btns.v-item-group.theme--dark.v-btn-toggle .v-btn {
  padding: 11px !important;
  margin: 4px 0 !important;
}

.team-table .v-select__selections {
  font-size: 12px;
}

</style>
